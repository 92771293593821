.single-project {
  text-align: center;
  width: 100%;
  margin: auto;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  padding-top: 15px;
  @media (min-width: $md) {
    margin-bottom: 50px;
  }

  // PROJECT TITLE
  .project-title-box {
    @media (min-width: $lg) {
      margin-bottom: 20px;
    }

    .project-title {
      font-size: 2.2rem;
    }
  }

  // CONTENT BELOW TITLE

  .project-content {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: $lg) {
      flex-direction: row;
    }
  }

  // PROJECT DESCRIPTION

  //
  .project-description-box {
    @media (min-width: $md) {
      margin: 0 20px;
    }

    .project-description {
      margin: 20px;
      font-size: 1.2rem;
      @media (min-width: $lg) {
        font-size: 1.4rem;
      }
    }
    .project-video {
      @media (max-width: 365px) {
        display: none;
      }
      max-width: 365px;
      margin: 0 5px;
      @media (min-width: $md) {
        max-width: 600px;
      }
      @media (min-width: $lg) {
        max-width: 500px;
        height: 300px;
      }
    }
  }

  // PROJECT CARD (RIGHT)

  // class for intersection observer
  .flip-effect {
    transform: rotateY(180deg);
  }

  // for flip
  .project-flip-box {
    // Flip if hover or tap whole card
    &:active {
      transform: rotateY(180deg);
    }
    &:hover {
      transform: rotateY(180deg);
    }

    height: 500px;
    perspective: 1000px;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    border: 1px solid black;
    margin: 20px;

    .project-img-box {
      height: 500px;
      .project-img-sm {
        width: 300px;
        height: 500px;
        object-fit: cover;
        object-position: center top;
      }
    }
  }

  .project-content-front {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
    opacity: 1;
  }
  .project-content-back {
    background: rgb(185, 184, 185);
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .img-back {
      opacity: 0.2;
    }
    .project-article {
      position: absolute;
      text-align: center;
      z-index: 1;
      top: 0;
      left: 0;
      margin: 15px;
      .project-note {
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
    .project-head {
      position: absolute;
      bottom: 0;
      width: 100%;
      .project-links {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        .project-url {
          height: 70px;
          width: 70px;
          background: white;
          border-radius: 50%;
          margin-bottom: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .project-icon {
          width: 50px;
        }
      }
    }
  }
  .project-title-box {
    line-height: 1.4;
    font-size: 1.4rem;
  }
}
