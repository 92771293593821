// FADE UP USED FOR ABOUT SECTION

.fade-up-animation {
  animation: fade-up 0.6s linear;
}

@keyframes fade-up {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes fade-up {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-up {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// FADE USED FOR MAIN BODY

.fade-enter-body {
  animation: fade-enter 0.5s linear;
}

@keyframes fade-enter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-enter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-enter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
