.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: calc(100vh - 80px);


  @media (min-width: $md) {
    padding-top: 20px;
  }

  .name-mobile {
    display: block;
    font-size: 2.2rem;
    margin: 20px 0;
    @media (min-width: $md) {
      display: none;
    }
  }
  .home-content {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .face-picture-box {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .face-picture {
      margin-bottom: 20px;
      border-radius: 50%;
      width: 250px;
      height: 250px;
      @media (min-width: $md) {
        width: 320px;
        height: 320px;
      }
    }
  }

  .info-title {
    line-height: 1.4;
    font-size: 1.2rem;
    margin-bottom: 20px;
    @media (min-width: $md) {
      line-height: 1.6;
      font-size: 2.4rem;
    }
  }

  .info {
    line-height: 1.4;
    font-size: 1.2rem;

    @media (min-width: $md) {
      line-height: 1.6;
      font-size: 2rem;
      height: 30%;
    }
    .info-parag {
      height: 120px;
      display: flex;
      flex-direction: column;

      @media (min-width: $md) {
        height: 300px;
      }
      .info-text {
        height: 40px;
        @media (min-width: $md) {
          height: 80px;
        }
      }
    }
  }
}
