// Screen sizes

$sm: 320px;
$md: 481px;
$lg: 769px;
$xlg: 1025px;
$xxlg: 1201px;

// colors
$light-text: rgb(212, 209, 212);
$off-black: #000505;
