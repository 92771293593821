.body {
  min-height: 665px;
  .block {
    height: 80px;
    position: sticky;
    top: 0;
  }

  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
  @media (min-width: $lg) {
    max-width: 1000px;
  }
  .content-container {
    z-index: 1;
  }

  .backdrop {
    background: linear-gradient(
      180deg,
      rgb(185, 184, 185) 0%,
      rgb(202, 170, 202) 20%,

      rgb(144, 66, 177) 70%,
      rgb(144, 66, 177) 100%
    );
    // background: rgb(196, 127, 226);
    // background: rgb(211, 175, 226);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
