.footer {
  background: $off-black;
  color: $light-text;
  bottom: 0;
  font-size: 0.9rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 400;
  width: 100%;
  padding: 5px 0;

  .footer-link {
    color: $light-text;
  }
}
