@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}

.header {
  width: 100%;
  background-color: #000505;
  margin: auto;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #d4d1d4;
  position: fixed;
  height: 80px;
  top: 0;
}

.header .name {
  font-weight: 500;
  margin-left: 25px;
  font-size: 1.8rem;
  font-style: italic;
}

@media (min-width: 320px) {
  .header .name {
    display: none;
  }
}

@media (min-width: 481px) {
  .header .name {
    display: block;
  }
}

.header .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
}

@media (min-width: 481px) {
  .header .nav {
    margin: 0;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

.header .nav .tab {
  margin-right: 25px;
  padding-bottom: 5px;
  background-color: #000505;
  color: #d4d1d4;
  border: none;
  cursor: pointer;
}

.header .nav .active {
  border-bottom: 1px solid #d4d1d4;
}

.header .nav .last {
  margin-right: 0;
}

@media (min-width: 481px) {
  .header .nav .last {
    margin-right: 25px;
  }
}

.body {
  min-height: 665px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 800px;
  margin: auto;
}

.body .block {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media (min-width: 769px) {
  .body {
    max-width: 1000px;
  }
}

.body .content-container {
  z-index: 1;
}

.body .backdrop {
  background: -webkit-gradient(linear, left top, left bottom, from(#b9b8b9), color-stop(20%, #caaaca), color-stop(70%, #9042b1), to(#9042b1));
  background: linear-gradient(180deg, #b9b8b9 0%, #caaaca 20%, #9042b1 70%, #9042b1 100%);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: calc(100vh - 80px);
}

@media (min-width: 481px) {
  .home {
    padding-top: 20px;
  }
}

.home .name-mobile {
  display: block;
  font-size: 2.2rem;
  margin: 20px 0;
}

@media (min-width: 481px) {
  .home .name-mobile {
    display: none;
  }
}

.home .home-content {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.home .face-picture-box {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home .face-picture-box .face-picture {
  margin-bottom: 20px;
  border-radius: 50%;
  width: 250px;
  height: 250px;
}

@media (min-width: 481px) {
  .home .face-picture-box .face-picture {
    width: 320px;
    height: 320px;
  }
}

.home .info-title {
  line-height: 1.4;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

@media (min-width: 481px) {
  .home .info-title {
    line-height: 1.6;
    font-size: 2.4rem;
  }
}

.home .info {
  line-height: 1.4;
  font-size: 1.2rem;
}

@media (min-width: 481px) {
  .home .info {
    line-height: 1.6;
    font-size: 2rem;
    height: 30%;
  }
}

.home .info .info-parag {
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (min-width: 481px) {
  .home .info .info-parag {
    height: 300px;
  }
}

.home .info .info-parag .info-text {
  height: 40px;
}

@media (min-width: 481px) {
  .home .info .info-parag .info-text {
    height: 80px;
  }
}

.about {
  margin: auto;
  min-height: 80vh;
}

@media (min-width: 481px) {
  .about {
    width: 100%;
  }
}

.about .about-me {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-bottom: 10vh;
}

@media (min-width: 481px) {
  .about .about-me {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
  }
}

.about .about-me .about-text {
  margin-bottom: 15px;
  line-height: 1.4rem;
  font-size: 1.2rem;
}

@media (min-width: 481px) {
  .about .about-me .about-text {
    line-height: 2.2rem;
    font-size: 1.6rem;
    margin-bottom: 25px;
  }
}

.about .about-me .about-last {
  margin-bottom: 0;
}

.projects {
  margin-bottom: 10vh;
}

.skills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  margin: auto;
  margin-top: 35px;
}

@media (min-width: 481px) {
  .skills {
    margin-top: 50px;
  }
}

.skills .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin: auto;
  margin-bottom: 35px;
}

@media (min-width: 481px) {
  .skills .row {
    margin-bottom: 50px;
  }
}

.skills .logo-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.skills .logo {
  width: 80px;
  margin: auto;
}

.contact {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

@media (min-width: 481px) {
  .contact {
    margin-bottom: 5vh;
  }
}

.contact .contact-icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contact .contact-icon-container .contact-icon {
  width: 75px;
  margin: 10px;
}

.contact .contact-link {
  color: #000505;
  text-decoration: none;
}

.footer {
  background: #000505;
  color: #d4d1d4;
  bottom: 0;
  font-size: 0.9rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  width: 100%;
  padding: 5px 0;
}

.footer .footer-link {
  color: #d4d1d4;
}

.single-project {
  text-align: center;
  width: 100%;
  margin: auto;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 35px;
  padding-top: 15px;
}

@media (min-width: 481px) {
  .single-project {
    margin-bottom: 50px;
  }
}

@media (min-width: 769px) {
  .single-project .project-title-box {
    margin-bottom: 20px;
  }
}

.single-project .project-title-box .project-title {
  font-size: 2.2rem;
}

.single-project .project-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 769px) {
  .single-project .project-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (min-width: 481px) {
  .single-project .project-description-box {
    margin: 0 20px;
  }
}

.single-project .project-description-box .project-description {
  margin: 20px;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .single-project .project-description-box .project-description {
    font-size: 1.4rem;
  }
}

.single-project .project-description-box .project-video {
  max-width: 365px;
  margin: 0 5px;
}

@media (max-width: 365px) {
  .single-project .project-description-box .project-video {
    display: none;
  }
}

@media (min-width: 481px) {
  .single-project .project-description-box .project-video {
    max-width: 600px;
  }
}

@media (min-width: 769px) {
  .single-project .project-description-box .project-video {
    max-width: 500px;
    height: 300px;
  }
}

.single-project .flip-effect {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.single-project .project-flip-box {
  height: 500px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  border: 1px solid black;
  margin: 20px;
}

.single-project .project-flip-box:active {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.single-project .project-flip-box:hover {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.single-project .project-flip-box .project-img-box {
  height: 500px;
}

.single-project .project-flip-box .project-img-box .project-img-sm {
  width: 300px;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
}

.single-project .project-content-front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.single-project .project-content-back {
  background: #b9b8b9;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.single-project .project-content-back .img-back {
  opacity: 0.2;
}

.single-project .project-content-back .project-article {
  position: absolute;
  text-align: center;
  z-index: 1;
  top: 0;
  left: 0;
  margin: 15px;
}

.single-project .project-content-back .project-article .project-note {
  font-weight: bold;
  margin-bottom: 20px;
}

.single-project .project-content-back .project-head {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.single-project .project-content-back .project-head .project-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.single-project .project-content-back .project-head .project-links .project-url {
  height: 70px;
  width: 70px;
  background: white;
  border-radius: 50%;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.single-project .project-content-back .project-head .project-links .project-icon {
  width: 50px;
}

.single-project .project-title-box {
  line-height: 1.4;
  font-size: 1.4rem;
}

.fade-up-animation {
  -webkit-animation: fade-up 0.6s linear;
          animation: fade-up 0.6s linear;
}

@keyframes fade-up {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-up {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.fade-enter-body {
  -webkit-animation: fade-enter 0.5s linear;
          animation: fade-enter 0.5s linear;
}

@keyframes fade-enter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-enter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
