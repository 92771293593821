.header {
  width: 100%;
  background-color: #000505;
  margin: auto;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $light-text;
  position: fixed;
  height: 80px;
  top: 0;

  .name {
    font-weight: 500;
    margin-left: 25px;
    font-size: 1.8rem;
    font-style: italic;
    @media (min-width: $sm) {
      display: none;
    }
    @media (min-width: $md) {
      display: block;
    }
  }

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media (min-width: $md) {
      margin: 0;
      justify-content: flex-end;
    }
    .tab {
      margin-right: 25px;
      padding-bottom: 5px;
      background-color: #000505;
      color: $light-text;
      border: none;
      cursor: pointer;
    }
    .active {
      border-bottom: 1px solid $light-text;
    }
    .last {
      margin-right: 0;
      @media (min-width: $md) {
        margin-right: 25px;
      }
    }
  }
}
