.about {
  margin: auto;

  min-height: 80vh;

  @media (min-width: $md) {
    width: 100%;
  }
  .about-me {
    width: 80%;
    margin: auto;
    text-align: center;
    margin-bottom: 10vh;

    @media (min-width: $md) {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 100px;
    }
    .about-text {
      margin-bottom: 15px;
      line-height: 1.4rem;
      font-size: 1.2rem;
      @media (min-width: $md) {
        line-height: 2.2rem;
        font-size: 1.6rem;
        margin-bottom: 25px;
      }
    }
    .about-last {
      margin-bottom: 0;
    }
  }
}
