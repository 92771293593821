.contact {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 15px;
  @media (min-width: $md) {
    margin-bottom: 5vh;
  }
  .contact-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .contact-icon {
      width: 75px;
      margin: 10px;
    }
  }
  .contact-link {
    color: $off-black;
    text-decoration: none;
  }
}
